@tailwind base;
@tailwind components;
@tailwind utilities;

@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar:hover {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track:hover {
  background: #eeeeee;
  width: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e3e3e6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9a9b9d;
}

.scroll {
  scrollbar-width: 30px;
  -ms-overflow-style: none;
}

input {
  outline: none;
  background: #eeeeee;
}

select {
  outline: none;
  background: #eeeeee;
}

select:focus {
  background: #fff;
  /* border: ; */
  outline: 2px solid #9a9b9d;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
  -moz-box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
  box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
}

input:focus {
  background: #fff;
  /* border: ; */
  outline: 2px solid #9a9b9d;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
  -moz-box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
  box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
}

textarea:focus {
  background: #fff;
  /* border: ; */
  outline: 2px solid #9a9b9d;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
  -moz-box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
  box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
}

textarea {
  outline: none;
  background: #eeeeee;
  resize: none;
}

option {
  outline: none;
  border: none;
}

option:focus {
  background: #fff;
  /* border: ; */
  outline: 2px solid #9a9b9d;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
  -moz-box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
  box-shadow: 0px 0px 9px 0px rgba(154, 155, 157, 1);
}

button:focus {
  outline: 4px solid #9a9b9d;
}

/* input::after{
  background: #fff;
} */
.MuiPaginationItem-outlinedPrimary {
  background-color: #555;
  color: red;
}

.Mui-selected {
  background-color: yellow;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#chart {
  width: 65%;
  max-width: 98%;
  margin: 0 auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 2px solid #ccc;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.slider:after {
  background-color: #fff;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: #ccc;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked+.slider {
  background-color: #484c7f;
  border: 2px solid #484c7f;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(22px);
  background-color: #fff;
}

/* Rounded sliders */
.slider.round {
  border-radius: 36px;
}

.slider.round:before {
  border-radius: 50%;
}

.accordion {
  border: none !important;
}

.accordion__button:hover {
  background: transparent !important;
}

.accordion__button {
  background: transparent !important;
  padding: 18px 0 !important;
  display: flex !important;
  align-items: center !important;
}

.accordion__panel {
  padding: 0 !important;
  padding-bottom: 10px;
  text-align: left;
}

.select-search-container {
  width: 100% !important;

}

.driver-application-input {
  border: 1px solid #ced4da;

}

.driver-application-input .select__control.css-13cymwt-control {
  background-color: #fff !important;
  outline: none !important;
}

.select__control.css-13cymwt-control {
  background-color: #EEEEEE !important;
  outline: none !important;
  border: none !important;
}

.select-search-container .select-search-select {
  outline: none !important;
  border: none !important;
}

.select-search-container li button {
  background-color: #ececec !important;
  color: black !important;

}

.MuiListItemAvatar-root {
  display: none !important;
}

.rs__cell>button {
  display: none !important;
}
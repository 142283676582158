@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

th {
  min-width: 80px !important;
}

td {
  display: table-cell;
  vertical-align: middle;
}















div#navbarSupportedContent {
  justify-content: center;
} 
.bg-dark{
  background-color: #FFFFFF !important;
}
#logsedf{
  width: 70%;
}
.slider-section{
  width: 100%;
  height: auto;
}
.header-title{
  text-align: center;
  margin-top: 20px;
}
.header-title h3{
  font-family: "inter" , sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 52px;
  text-transform: capitalize;
}
.appli-title{
  padding: 0 30px;
  background-color: #4B1B17;
  margin-top: 20px;
  border-radius: 6px;
}
.appli-title h4{
  font-family: "inter" , sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.7em;
  text-transform: capitalize;
  color: #FFFFFF;
}
.input-group{
  flex-wrap: nowrap !important;
}
.input-group-text {
  border-radius: 0rem !important;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}
.current-text h4{
  font-family: "inter" , sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  text-transform: capitalize;
  padding-top: 20px;
}
.lic-text p {
  font-family: "inter" , sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-transform: capitalize;
}
#divMsg{
  display: none;
}
.footer-section{
  background-color: #4B1B17;
  padding: 40px;
  width: 100%;
  height: auto;
}
.footer-menu h4{
  font-family: 'Inter' , sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px; 
  /* identical to box height */
  text-transform: uppercase;
  color: #FFFFFF;
}
.footer-menu ul li a{
  font-family: 'Inter' , sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  /* identical to box height */ 
  color: #FFFFFF;
  text-decoration: none;
}
.footer-menu ul li {
  list-style: none;
  padding: 4px 0px;
  font-family: 'Inter' , sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  /* identical to box height */ 
  color: #FFFFFF;
  text-decoration: none;
}
.footer-menu ul {
  padding: 0px 10px;
}
#foot-icon {
  padding-right: 10px;
  font-size: 14px;
  color: #E5B34C;
}
.botfo-section{
  width: 100%;
  height: auto;
}
.footerbar-text {
  text-align: center;
}
.footerbar-text p {
  padding-top: 1rem;
}
.btn-secondary{
  background-color: #4B1B17;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #f2b900;
}
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  counter-reset: step;
  text-align: center;
}
.pro{
  margin: 35px 0px;
}
.pro ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
#progressbar .active {
  color: #000000;
}
#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 16.64%;
  float: left;
  position: relative;
}
#progressbar li.active:before, #progressbar li.active:after {
  background: #4B1B17;
}
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

@media screen and (min-width:768px) and (max-width:991px) {
  div#navbarSupportedContent {
      justify-content: center;
  } 
  
}


@media screen and (min-width: 0px) and (max-width: 768px) {
  div#navbarSupportedContent {
      justify-content: center;
  } 
  .header-title h3 {
      font-family: "inter" , sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 52px;
      text-transform: capitalize;
  }
  .appli-title h4 {
      font-family: "inter" , sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 2.7em;
      text-transform: capitalize;
      color: #FFFFFF;
  }
  .current-text h4 {
      font-family: "inter" , sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-transform: capitalize;
      padding-top: 20px;
  }
  .lic-text p {
      font-family: "inter" , sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.7em;
      text-transform: capitalize;
      padding-top: 20px;
  }
  #progressbar li:before {
      content: counter(step);
      counter-increment: step;
      width: 40px;
      height: 40px;
      line-height: 35px;
      display: block;
      font-size: 18px;
      color: #ffffff;
      background: lightgray;
      border-radius: 50%;
      margin: 0 auto 10px auto;
      padding: 2px;
  }
  #progressbar li:after {
      content: '';
      width: 100%;
      height: 2px;
      background: lightgray;
      position: absolute;
      left: 0;
      top: 18px;
      z-index: -1;
  }
  .pro {
      margin: 10px 0px;
  }
      
}